* {
  font-family: 'Poppins', sans-serif !important;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(2,6,15,1);
}

